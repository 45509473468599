import Authenticator from "./Authenticator";
import { Result } from "./interfaces/Result";
import { ServiceError } from "./interfaces/Error";
import { Auction, AuctionItem, Bid, UserBid } from "./interfaces/Auction";

const axios = require("axios").default;

export default class AuctionService {
    async getAuctions(eventId: number): Promise<Result<Auction[]>> {
        try {
            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/events/${eventId}/auctions`);
            if (response.status === 200) {
                const auctions: Auction[] = response.data;
                return auctions;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }

    async getAuctionItems(auctionId: number): Promise<Result<AuctionItem[]>> {
        try {
            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/auctions/${auctionId}/items`);
            if (response.status === 200) {
                const items: AuctionItem[] = response.data;
                return items;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }

    async getAuctionItem(auctionId: number, itemId: number): Promise<Result<AuctionItem>> {
        const authenticator = new Authenticator();
        let token = "";

        if (authenticator.isAuthenticated()) {
            token = authenticator.getToken();
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/auctions/${auctionId}/items/${itemId}`, {
                headers: {
                    "Authorization": token
                }
            });
            if (response.status === 200) {
                const item: AuctionItem = response.data;
                return item;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }

    async placeBid(auctionItemId: number, bidAmount: number): Promise<Result<boolean>> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/auction-items/bid`, {
                auctionItemId: auctionItemId,
                maxBidAmount: bidAmount
            }, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                return true;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }
            
            return new ServiceError(-1, "Unable to make the network request.");
        }
    }

    async getBids(auctionItemId: number): Promise<Result<Bid[]>> {
        try {
            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/auction-items/${auctionItemId}/bids`);
            if (response.status === 200) {
                const bids: Bid[] = response.data;
                return bids;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }

    async addToWatchList(auctionItemId: number): Promise<Result<boolean>> {
        try {
            const authenticator = new Authenticator();
            
            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/auction-items/watch`, {
                "auctionItemId": auctionItemId
            }, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });
            if (response.status === 200) {
                return true;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }

    async removeFromWatchList(auctionItemId: number): Promise<Result<boolean>> {
        try {
            const authenticator = new Authenticator();

            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/auction-items/unwatch`, {
                "auctionItemId": auctionItemId
            }, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });
            if (response.status === 200) {
                return true;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }

    async getWatchList(): Promise<Result<AuctionItem[]>> {
        try {
            const authenticator = new Authenticator();

            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/auction-items/watchlist`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const items: AuctionItem[] = response.data;
                return items;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }

    async getFeaturedAuctionItems(): Promise<Result<AuctionItem[]>> {
        try {
            const authenticator = new Authenticator();
            let token = "";

            if (authenticator.isAuthenticated()) {
                token = authenticator.getToken();
            }

            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/auction-items/featured`, {
                headers: {
                    "Authorization": token
                }
            });

            if (response.status === 200) {
                const items: AuctionItem[] = response.data;
                return items;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }

    async getUserBids(): Promise<Result<UserBid[]>> {
        try {
            const authenticator = new Authenticator();

            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/users/bids`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const bids: UserBid[] = response.data;
                return bids;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request");
        }
    }
}
