import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import { StripeProvider } from 'react-stripe-elements';
import ScrollToTop from "react-router-scroll-top";

import Authenticator from "./core/Authenticator";
import Landing from "./pages/landing/Landing";
import AboutPage from "./pages/about/AboutPage";
import SponsorsPage from "./pages/sponsors/SponsorsPage";
import LoginPage from "./pages/login/LoginPage";
import SignupPage from "./pages/signup/SignupPage";
import TicketInfoPage from "./pages/tickets/TicketInfoPage";
import TicketPurchasePage from "./pages/tickets/TicketPurchasePage";
import TicketCheckoutPage from "./pages/tickets/TicketCheckoutPage";
import TicketConfirmationPage from "./pages/ticket-confirmation/TicketConfirmationPage";
import DonatePage from "./pages/donate/DonatePage";
import DonateConfirmationPage from "./pages/donate/DonateConfirmationPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import OnlineAuctionPage from "./pages/auctions/OnlineAuctionPage";
import AuctionItemPage from "./pages/auctions/AuctionItemPage";
import LiveAuctionPage from "./pages/auctions/LiveAuctionPage";
import PasswordResetPage from "./pages/password-reset/PasswordResetPage";
import NewPasswordPage from "./pages/password-reset/NewPasswordPage";

interface RouteProps {
    path: string;
    component: typeof Component;
}

const ProtectedRoute = ({component: Component, ...rest}: RouteProps) => {
    const authenticator = new Authenticator();
  
    return (
        <Route {...rest}
            render={props => !authenticator.isAuthenticated() ? 
                ( <Redirect to={{ pathname: `/login?origin=${rest.path}` }} /> ) : ( <Component {...props} /> )} />
    );
  }

const App: React.FC = () => {
    const apiKey = process.env.REACT_APP_STRIPE_API_KEY;

    return (
        <StripeProvider apiKey={apiKey!}>
            <Router>
                <ScrollToTop>
                    <Switch>
                        <ProtectedRoute path="/dashboard" component={DashboardPage} />
                        <ProtectedRoute path="/tickets/confirmation" component={TicketConfirmationPage} />
                        <ProtectedRoute path="/tickets/purchase" component={TicketPurchasePage} />
                        <ProtectedRoute path="/tickets/checkout" component={TicketCheckoutPage} />
                        <Route path="/tickets" component={TicketInfoPage} />
                        <Route path="/donate/confirmation" component={DonateConfirmationPage} />
                        <Route path="/donate" component={DonatePage} />
                        <Route path="/auction/online/:auctionId/item/:id" component={AuctionItemPage} />
                        <Route exact path="/auction/online" component={OnlineAuctionPage} />
                        <Route exact path="/auction/live" component={LiveAuctionPage} />
                        <Route exact path="/about">
                            <AboutPage />
                        </Route>
                        <Route exact path="/login">
                            <LoginPage />
                        </Route>
                        <Route exact path="/password/reset">
                            <PasswordResetPage />
                        </Route>
                        <Route path="/password/new">
                            <NewPasswordPage />
                        </Route>
                        <Route exact path="/signup">
                            <SignupPage />
                        </Route>
                        <Route exact path="/sponsors">
                            <SponsorsPage />
                        </Route>
                        <Route exact path="/">
                            <Landing />
                        </Route>
                    </Switch>
                </ScrollToTop>
            </Router>
        </StripeProvider>
    );
}

export default App;
