import Authenticator from "./Authenticator";
import { PaymentMethod, AddPaymentMethodResponse } from "./interfaces/PaymentMethod";
import { Transaction } from "./interfaces/Transaction";

const axios = require("axios").default;

export default class PaymentService {
    async getPaymentMethods(): Promise<PaymentMethod[]> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/payment-methods`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const paymentMethods: PaymentMethod[] = response.data;
                return paymentMethods;
            }

            return [];
        } catch (error) {
            return [];
        }
    }

    async addPaymentMethod(paymentMethod: PaymentMethod): Promise<string | null> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/payment-methods/new`, {
                "tokenId": paymentMethod.tokenId,
                "cardType": paymentMethod.cardType,
                "expMonth": String(paymentMethod.expMonth),
                "expYear": String(paymentMethod.expYear),
                "lastFour": String(paymentMethod.lastFour)
            }, { 
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 201) {
                const info: AddPaymentMethodResponse = response.data;
                return info.cardId;
            }

            return null;
        } catch (error) {
            return null;
        }
    }

    async chargeDonationForUser(cardId: string, donationAmount: number): Promise<boolean> {
        let token = "";

        const authenticator = new Authenticator();
        if (authenticator.getToken() != null) {
            token = authenticator.getToken()!;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/donate`, {
                cardId: cardId,
                donationAmount: donationAmount
            }, {
                headers: {
                    "Authorization": token
                }
            });

            if (response.status === 201) {
                return true;
            }

            return false;
        } catch (error) {
            return false;
        }
    }

    async chargeDonationForGuest(tokenId: string, name: string, email: string, cardType: string, lastFour: string, donationAmount: number): Promise<boolean> {
        try {
            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/donate`, {
                tokenId: tokenId,
                name: name,
                email: email,
                cardType: cardType,
                lastFour: lastFour,
                donationAmount: donationAmount
            });

            if (response.status === 201) {
                return true;
            }

            return false;
        } catch (error) {
            return false;
        }
    }

    async getTransactions() {
        const authenticator = new Authenticator();

        try {
            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/transactions`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const transactions: Transaction[] = response.data;
                return transactions;
            }

            return [];
        } catch (error) {
            return [];
        }
    }
}
