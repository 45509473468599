import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import $ from "jquery";


import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Authenticator from "../../core/Authenticator";

interface TicketInfoPageProps {
    history: History;
}

const TicketInfoPage: React.FC<TicketInfoPageProps> = ({history}: TicketInfoPageProps) => {
    useEffect(() => {
        const authenticator = new Authenticator();
        if (authenticator.isAuthenticated()) {
            $("#btn-group-authenticated").show();
            $("#btn-group-unauthenticated").hide();
            $("#alert-pip").hide();
            $("#instructions-auth").hide();
        } else {
            $("#btn-group-authenticated").hide();
            $("#btn-group-unauthenticated").show();
        }
    }, []);

    const onLoginClicked = (event: React.MouseEvent) => {
        event.preventDefault();
        history.push("/login?origin=/tickets/purchase");
    };

    const onSignUpClicked = (event: React.MouseEvent) => {
        event.preventDefault();
        history.push("/signup?origin=/tickets/purchase");
    }

    const onContinueClicked = (event: React.MouseEvent) => {
        event.preventDefault();
        history.push("/tickets/purchase");    
    };

    return (
        <div className="d-flex flex-column full-height">
            <Navbar history={history} />
            <div className="container flex-grow-1 mt-4 mb-4">
                <div className="row justify-content-center">
                    <div className="col-sm-8 text-center">
                        <h5 className="section-header">Tickets</h5>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-sm-8">
                        <table className="table table-bordered">
                            <tr>
                                <th className="table-active">$110.00</th>
                                <td>per person</td>
                            </tr>
                            <tr className="collapse">
                                <th className="table-active">$80.00</th>
                                <td>per person (age 70+; school staff)</td>
                            </tr>
                            <tr className="collapse">
                                <th className="table-active">$80.00</th>
                                <td>donation per ticket for staff and clergy</td>
                            </tr>
                        </table>
                        <p id="instructions-auth">
                            Login or create an account below to purchase tickets. You'll use the same account once bidding is open for the online auction. The number card you'll receive for the live auction on the night of the Gala will also be associated with your account.
                        </p>
                        <div id="alert-pip" className="alert alert-primary mt-4">
                            <small><span className="font-weight-bold">Have a PSA PIP Account?</span></small><br />
                            You can use your PIP login when purchasing tickets and bidding on items at the Gala. Use the login option below to get started.
                        </div>
                        <div id="btn-group-authenticated" className="mt-4 text-center collapse">
                            <div className="col-sm-12">
                                <button type="button" className="btn btn-outline-primary w-100" onClick={onContinueClicked}>Continue to purchase</button>
                            </div>
                        </div>
                        <div id="btn-group-unauthenticated" className="mt-4 text-center collapse">
                            <div className="row">
                                <div className="col-sm-6">
                                    <button type="button" className="btn btn-outline-primary w-100" onClick={onLoginClicked}>Login</button>
                                </div>
                                <div className="col-sm-6 mt-4 mt-sm-0">
                                    <button type="button" className="btn btn-outline-primary w-100" onClick={onSignUpClicked}>Create a Gala account</button>
                                </div>
                            </div>
                        </div>
                        <div className="alert alert-info mt-5">
                            Any questions, comments or concerns please reach out to <a href="mailto:help@stmartinpsa.org">help@stmartinpsa.org</a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer history={history} />
        </div>
    );
}

export default withRouter(TicketInfoPage);
