import React from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import lourdesLogo from "../../images/platinum_sponsor.png";
import dlFlowTech from "../../images/sponsor_005.jpg";
import kennedy from "../../images/sponsor_012.jpg";
import brookeBrady from "../../images/sponsor_002.png";
import hillcroft from "../../images/sponsor_hillcroft.png";
import collectionBureau from "../../images/sponsor_006.png";
import smiles from "../../images/sponsor_001.jpg";
import bonura from "../../images/sponsor_010.png";
import communityCare from "../../images/sponsor_007.png";
import ginoPizza from "../../images/sponsor_008.png";
import effmanMusic from "../../images/sponsor_003.png";
import riverRadiology from "../../images/sponsor_011.jpg";
import dutchess from "../../images/sponsor_013.png";
import doyle from "../../images/doyle_sponsor.jpg";
import facility from "../../images/facility_sponsor.png";
import blondin from "../../images/sponsor_018.png";
import effman from "../../images/effman.png";
import statefarm from "../../images/sponsor_statefarm.png";
import veith from "../../images/sponsor_veith.png";
import z3 from "../../images/sponsor_017.png";
import hudsonValley from "../../images/hudson_valley_team_sponsor.png";
import homesteadFunding from "../../images/homestead_funding.png";
import sponsor20 from "../../images/sponsor_020.png";
import millbrook from "../../images/millbrook_sponsor.png";
import adams from "../../images/adams_sponsor.png";
import pbaSponsor from "../../images/pba_sponsor.png";
import russoSponsor from "../../images/russo_sponsors.png";
import mjn from "../../images/mjn_sponsor.png";
import lola from "../../images/sponsor_lola.png";

interface SponsorsPageProps {
    history: History;
}

const SponsorsPage: React.FC<SponsorsPageProps> = ({history}: SponsorsPageProps) => {
    return (
        <div>
            <Navbar history={history} />
            <div className="container mt-4">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h5 className="section-header">Platinum Sponsors</h5>
                        <div className="mt-4">
                            <img src={dlFlowTech} className="sponsor" />
                            <img src={sponsor20} className="sponsor" />
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-sm-12 text-center">
                        <h5 className="section-header">Gold Sponsors</h5>
                        <div className="mt-4">
                            <img src={kennedy} className="sponsor" />
                            <img src={veith} className="sponsor" />
                            <img src={z3} className="sponsor" />
                            <img src={millbrook} className="sponsor" />
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-sm-12 text-center">
                        <h5 className="section-header">Silver Sponsors</h5>
                        <div className="mt-4">
                            <img src={brookeBrady} className="sponsor" />
                            <img src={communityCare} className="sponsor" />
                            <img src={lourdesLogo} className="sponsor" />
                            <img src={hillcroft} className="sponsor" />
                            <img src={homesteadFunding} className="sponsor" />
                            <img src={pbaSponsor} className="sponsor" />
                            <img src={smiles} className="sponsor" />
                            <img src={mjn} className="sponsor" />
                            <img src={lola} className="sponsor" />
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-sm-12 text-center">
                        <h5 className="section-header">Bronze Sponsors</h5>
                        <div className="mt-4">
                            <img src={ginoPizza} className="sponsor" />
                            <img src={blondin} className="sponsor p-2" />
                            <img src={effman} className="sponsor" />
                            <img src={statefarm} className="sponsor" />
                            <img src={hudsonValley} className="sponsor" />
                            <img src={adams} className="sponsor" />
                            <img src={russoSponsor} className="sponsor" />
                            <img src={effmanMusic} className="sponsor collapse" />
                            <img src={dutchess} className="sponsor collapse" />
                            <img src={facility} className="sponsor collapse" />
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-sm-12 text-center">
                        <h5 className="section-header">Donors</h5>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-sm-4 text-center">
                        Angela Maffia M.S. L.A.C.
                        <br/>
                        Auto Zone Rt 9 Poughkeepsie
                        <br/>
                        Barbaro Millbrook
                        <br/>
                        Bardavon Opera House
                        <br/>
                        Beekman Arms & Delamater Inn
                        <br/>
                        Bellizzi Jewelers
                        <br/>
                        Bounce Sports & Entertainment
                        <br/>
                        Brooklyn Botanic Garden
                        <br/>
                        Brother's Trattoria
                        <br/>
                        Buttermilk Falls Inn & Spa
                        <br/>
                        Chloe + Lois
                        <br/>
                        Clarity Through Organization
                        <br/>
                        Crown Maple
                        <br/>
                        Cutco / Annie Kaftan
                        <br/>
                        DC Sports
                        <br/>
                        Dutchess County Sheriff's Office
                        <br/>
                        Farmers & Chefs
                        <br/>
                        Elizabeth Boutique
                        <br/>
                        Essie's Restaurant
                        <br/>
                        Every Catholic
                        <br/>
                        Facial Plastic Reconstructive & Laser Surgery
                        <br/>
                        Foam & Wash
                        <br/>
                        Freddy B
                        <br/>
                        Gigi Trattoria
                        <br/>
                        Gold's Gym
                        <br/>
                        Half Time
                        <br/>
                        Hudson & Packard
                        <br/>
                        Hudson Valley's Best
                        <br/>
                        HV Massageworks
                        <br/>
                        HV Renegades
                        <br/>
                        J. Murphy's On Main
                        <br/>
                        Joan Zammit
                        <br/>
                        Jolie Aesthetics & Wellness
                        <br/>
                        Karl Family Farms
                        <br/>
                        Lola's Cafe & Catering
                        <br/>
                        Marist College Sports
                        <br/>
                        Michelle Barone Media
                        <br/>
                        Mid-Hudson Discovery Museum
                    </div>
                    <div className="col-sm-4 text-center">
                        Mindful Yoga with Liz
                        <br/>
                        Millbrook Vineyards & Winery
                        <br/>
                        Mirbeau Inn & Spa
                        <br/>
                        Mohegan Sun
                        <br/>
                        Mohonk Mtn. House
                        <br/>
                        Mountain Float Spa
                        <br/>
                        New York Academy of Ballet
                        <br/>
                        Novak Family
                        <br/>
                        Oasis Medispa
                        <br/>
                        Osborne's Flower Shoppe
                        <br/>
                        Our Lady of Lourdes HS
                        <br/>
                        Page Lumber
                        <br/>
                        Pastry Garden
                        <br/>
                        Pelesz Family
                        <br/>
                        Planet Fitness
                        <br/>
                        Rossi & Sons
                        <br/>
                        Rugged Rosaries 
                        <br/>
                        Sallye Ander
                        <br/>
                        Salt Therapy Room
                        <br/>
                        Schatzi's Pub & Bier Garden
                        <br/>
                        Sloop Brewing Co.
                        <br/>
                        Sock Religious
                        <br/>
                        Spins Bowl
                        <br/>
                        SMdP School Staff
                        <br/>
                        Star 2B
                        <br/>
                        Starbucks 
                        <br/>
                        Take Time Out For Beauty
                        <br/>
                        Texas Roadhouse
                        <br/>
                        The Art Effect
                        <br/>
                        The Blushery
                        <br/>
                        The L Salon
                        <br/>
                        The Powelton Club
                        <br/>
                        Trans-Tek Transmission & Auto Repair
                        <br/>
                        Viscount Wines & Liquor
                        <br/>
                        Zeus Brewing Co.
                        <br/>
                        Zitz Family
                    </div>
                </div>
            </div>
            <Footer history={history} />
        </div>
    );
}

export default withRouter(SponsorsPage);
