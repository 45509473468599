import React, { useEffect } from "react";
import { History } from "history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";

import Authenticator from "../core/Authenticator";

import { faBars } from "@fortawesome/free-solid-svg-icons";
import schoolCross from "../images/logo.svg";

interface NavbarProps {
    history: History;
}

const Navbar: React.FC<NavbarProps> = ({history}: NavbarProps) => {
    useEffect(() => {
        const authenticator = new Authenticator();
        if (authenticator.isAuthenticated()) {
            $("#btn-account").text("My Dashboard");
        }
    }, []);

    const onBuyTicketsClicked = async (event: React.MouseEvent) => {
        event.preventDefault();
        history.push("/tickets");
    };

    const onDonateClicked = async (event: React.MouseEvent) => {
        event.preventDefault();
        history.push("/donate");
    };

    const onAccountClicked = async (event: React.MouseEvent) => {
        const authenticator = new Authenticator();
        if (authenticator.isAuthenticated()) {
            history.push("/dashboard");
        } else {
            history.push("/login");
        }
    };

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-nav">
                <button className="btn btn-link d-lg-none ml-n1 mr-2" type="button" data-toggle="collapse" data-target="#navbar-menu">
                    <FontAwesomeIcon icon={faBars} size="lg" color="#1b75bb" />
                </button>
                <div>
                <a className="navbar-brand" href="/">
                    <img src={schoolCross} className="cross d-none d-sm-inline" /> <span className="ml-sm-2">Gala & Auction 2024</span>
                </a>
                </div>
                <div id="navbar-menu" className="collapse navbar-collapse">
                    <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                        <li className="nav-item">
                            <a href="/about" className="nav-link">About</a>
                        </li>
                        <li className="nav-item">
                            <a href="/sponsors" className="nav-link">Sponsors</a>
                        </li>
                        <li className="nav-item">
                            <a href="/auction/online" className="nav-link">Online Auction</a>
                        </li>
                        <li className="nav-item">
                            <a href="/auction/live" className="nav-link">Live Auction</a>
                        </li>
                    </ul>
                    <div className="my-2 my-lg-0 ml-auto">
                        <button className="btn btn-sm btn-outline-primary mx-3 my-lg-0 ml-auto mt-3" onClick={onBuyTicketsClicked}>Buy Tickets</button>
                        <button className="btn btn-sm btn-outline-primary mx-3 my-lg-0 ml-auto mt-3" onClick={onDonateClicked}>Donate</button>
                        <button id="btn-account" className="btn btn-sm btn-outline-primary mx-3 my-lg-0 ml-auto mt-3" onClick={onAccountClicked}>Login</button>
                    </div>
                </div>
            </nav>
            <div className="divider"></div>
        </div>
    );
}

export default Navbar;
