import React from "react";
import { withRouter } from "react-router-dom";
import Navbar from "../../components/Navbar";
import TicketPurchase from "../../core/interfaces/TicketPurchase";
import { Elements } from "react-stripe-elements";
import Payment from "../../components/Payment";
import Authenticator from "../../core/Authenticator";
import Footer from "../../components/Footer";

const TicketCheckoutPage: React.FC<any> = ({history}: any) => {
    const order: TicketPurchase = history.location.state;
    if (order.guestCardName === undefined) {
        const authenticator = new Authenticator();
        const user = authenticator.getUser();

        if (user != null) {
            order.guestCardName = `${user.firstName} ${user.lastName}`

            if ((order.numGuests + order.numGuestsDiscounted) > 1) {
                order.guestCardName += " & Guest";
            }
        }
    }

    return (
        <div>
            <Navbar history={history} />
            <div className="container mt-4">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <ul className="list-group">
                            <li className="list-group-item list-group-item-secondary">
                                <span className="font-weight-bold">Your Details</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                <span>
                                    <span className="font-weight-bold">Name for table card</span>
                                    <br />
                                    {order.guestCardName}
                                </span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                <span>
                                    <span className="font-weight-bold">Grade(s) of children</span>
                                    <br />
                                    {order.childGrade ?? 'N/A'}
                                </span>
                            </li>
                            { order.seatingNotes !== undefined &&
                            <li className="list-group-item d-flex">
                                <span>
                                    <span className="font-weight-bold">People I would like to be seated with</span>
                                    <br />
                                    {order.seatingNotes}
                                </span>
                            </li>
                            }
                        </ul>
                        <ul className="list-group mt-4">
                            <li className="list-group-item list-group-item-secondary">
                                <span className="font-weight-bold">Your Order</span>
                            </li>
                            { order.numGuests > 0 &&
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                <span>
                                    Guests
                                    <br />
                                    <span className="font-italic">{order.numGuests} x $110</span>
                                </span>
                                <span>
                                    ${order.numGuests * 110}
                                </span>
                            </li>
                            }
                            { order.numGuestsDiscounted > 0 &&
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                <span>
                                    Discounted Guests
                                    <br />
                                    <span className="font-italic">{order.numGuestsDiscounted} x $65</span>
                                </span>
                                <span>
                                    ${order.numGuestsDiscounted * 65}
                                </span>
                            </li>
                            }
                            { order.numGuestsSponsored > 0 &&
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                <span>
                                    Sponsored Guests
                                    <br />
                                    <span className="font-italic">{order.numGuestsSponsored} x $65</span>
                                </span>
                                <span>
                                    ${order.numGuestsSponsored * 65}
                                </span>
                            </li>
                            }
                            <li className="list-group-item list-group-item-info d-flex justify-content-between align-items-center">
                                <span className="font-weight-bold">Total:</span>
                                <span className="font-weight-bold">${(order.numGuests * 110) + (order.numGuestsDiscounted * 65) + (order.numGuestsSponsored * 65)}</span>
                            </li>
                        </ul>
                        <Elements>
                            <Payment purchase={order} paymentTotal={(order.numGuests * 110) + (order.numGuestsDiscounted * 65) + (order.numGuestsSponsored * 65)} history={history} />
                        </Elements>
                    </div>
                </div>
            </div>
            <Footer history={history} />
        </div>
    );
}

export default withRouter(TicketCheckoutPage);
