import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import queryString from "query-string";
import $ from "jquery";

import Authenticator from "../../core/Authenticator";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

async function executeLogin(email: string, password: string, history: History, origin: string) {
    $("#login-alert").hide();
    
    $("#login-button").hide();
    $("#login-loading").show();

    if (email === undefined || email === "") {
        $("#login-alert").text("Email is required.");
        $("#login-alert").show();

        $("#login-button").show();
        $("#login-loading").hide();

        return;
    }

    if (password === undefined || password === "") {
        $("#login-alert").text("Password is required.");
        $("#login-alert").show();

        $("#login-button").show();
        $("#login-loading").hide();

        return;
    }

    const authenticator = new Authenticator();
    const user = await authenticator.login(email, password);

    if (user !== null) {
        if (origin != null && origin !== "") {
            history.push(origin);
        } else {
            history.push("/dashboard");
        }
        
    } else {
        $("#login-loading").hide();
        $("#login-button").show();

        $("#login-alert").text("Error logging in. Please try again.");
        $("#login-alert").show();
    }
}

interface LoginPageProps {
    history: History;
}

const LoginPage: React.FC<LoginPageProps> = ({history}: LoginPageProps) => {
    const authenticator = new Authenticator();
    if (authenticator.isAuthenticated()) {
        history.replace("/dashboard");
    }
    
    const params = queryString.parse(history.location.search);
    let origin = params["origin"] as string;

    if (origin === undefined) {
        origin = "";
    }

    const [loginEmail, setLoginEmail] = useState();
    const handleLoginEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => setLoginEmail(event.target.value);

    const [loginPassword, setLoginPassword] = useState();
    const handleLoginPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setLoginPassword(event.target.value);

    const loginFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        executeLogin(loginEmail, loginPassword, history, origin);
    };

    return (
        <div className="d-flex flex-column full-height">
            <Navbar history={history} />
            <div className="container flex-grow-1 mt-4 mb-4">
                <div className="row justify-content-center">
                    <div className="col-lg-5 order-lg-2">
                        <div className="card">
                            <div className="card-header">
                                Login
                            </div>
                            <div className="card-body">
                                <div className="alert alert-primary">
                                    <small><span className="font-weight-bold">Have a PSA PIP Account?</span><br />Login with your existing email and password.</small>
                                    <p><small>No PSA account? <a href={"/signup?origin="+origin}>Sign up here</a> for a Gala & Auction account.</small></p>
                                </div>
                                <div id="login-alert" className="alert alert-danger collapse mt-4"></div>
                                <form onSubmit={loginFormSubmit}>
                                    <input type="email" className="form-control mt-2" placeholder="Email" onChange={handleLoginEmailChange} />
                                    <input type="password" className="form-control mt-2" placeholder="Password" onChange={handleLoginPasswordChange} />
                                    <div className="mt-4 text-center">
                                        <div className="d-flex justify-content-center">
                                            <div id="login-loading" className="spinner-border collapse" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        <button id="login-button" type="submit" className="btn btn-primary col-12">Login</button>
                                        <p className="mt-3">
                                            Need help? Contact us at <a className="psa-inline-button" href="mailto:help@stmartinpsa.org">
                                                help@stmartinpsa.org
                                            </a>
                                            <br/>
                                            <a className="psa-inline-button" href="/password/reset">
                                                Forgot password? Click here to reset.
                                            </a>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="mt-4 text-center">
                            <button type="button" className="btn btn-success w-100" onClick={(e) => history.push("/signup?origin="+origin)}>Don't have an account? Sign up here.</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer history={history} />
        </div>
    );
}

export default withRouter(LoginPage);
