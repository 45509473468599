import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import { faCcAmex, faCcVisa, faCcMastercard, faCcDiscover, faCcDinersClub } from "@fortawesome/free-brands-svg-icons";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { PaymentMethod } from "../../core/interfaces/PaymentMethod";
import PaymentService from "../../core/PaymentService";
import { Transaction, TransactionItem } from "../../core/interfaces/Transaction";
import { AuctionItem, UserBid } from "../../core/interfaces/Auction";
import AuctionService from "../../core/AuctionService";
import { isSuccess } from "../../core/interfaces/Result";

interface DashboardPageProps {
    history: History;
}

async function fetchPaymentMethods(setPaymentMethods: React.Dispatch<PaymentMethod[]>) {
    const service = new PaymentService();
    
    const cards = await service.getPaymentMethods();
    setPaymentMethods(cards);
}

async function fetchTransactions(setTransactions: React.Dispatch<Transaction[]>) {
    const service = new PaymentService();

    const transactions = await service.getTransactions();
    setTransactions(transactions);
}

async function fetchWatchList(setWatchList: React.Dispatch<AuctionItem[]>) {
    const service = new AuctionService();

    const items = await service.getWatchList();
    if (isSuccess(items)) {
        setWatchList(items);
    }
}

async function fetchUserBids(setUserBids: React.Dispatch<UserBid[]>) {
    const service = new AuctionService();

    const bids = await service.getUserBids();
    if (isSuccess(bids)) {
        setUserBids(bids);
    }
}

const DashboardPage: React.FC<DashboardPageProps> = ({history}: DashboardPageProps) => {
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [watchList, setWatchList] = useState<AuctionItem[]>([]);
    const [userBids, setUserBids] = useState<UserBid[]>([]);

    useEffect(() => {
        fetchPaymentMethods(setPaymentMethods);
        fetchTransactions(setTransactions);
        fetchWatchList(setWatchList);
        fetchUserBids(setUserBids);
    }, []);

    return (
        <div className="d-flex flex-column full-height">
            <Navbar history={history} />
            <div className="container flex-grow-1 mt-4 mb-4">
                <div className="row justify-content-center">
                    <div className="col-sm-8">
                        <div className="card">
                            <div className="card-header">
                                Bids
                            </div>
                            <div className="card-body m-0 p-0">
                                <ul className="list-group list-group-flush m-0 p-0">
                                    {userBids.map((bid) =>
                                    <li key={bid.id} className="list-group-item flex-column align-items-start auction-pointer" 
                                            onClick={(e) => history.push("/auction/online/"+bid.auctionItem.auction.id+"/item/"+bid.auctionItem.id)}>
                                        <div className="row">
                                            <div className="col-sm-10 my-auto">
                                                {bid.id === bid.auctionItem.highestBid.id && <label className="badge badge-sm badge-primary mr-3">Highest Bid</label>}
                                                {bid.auctionItem.title}
                                                <br/>
                                                <span className="text-muted">{moment(new Date(bid.createdAt * 1000)).format("MMMM Do YYYY h:mm a")}</span>
                                            </div>
                                            <div className="col-sm-2 my-auto">
                                                <label className="badge badge-success">${bid.bidAmount}.00</label>
                                            </div>
                                        </div>
                                    </li>
                                    )}
                                    {userBids.length === 0 &&
                                    <li className="list-group-item flex-column mx-auto empty-list">
                                        <p className="text-muted mt-3">No bids</p>
                                    </li>
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="card mt-4">
                            <div className="card-header">
                                Transactions
                            </div>
                            <div className="card-body m-0 p-0">
                                <ul className="list-group list-group-flush m-0 p-0">
                                    {transactions.map((transaction) =>
                                    <li key={transaction.id} className="list-group-item flex-column align-items-start">
                                        <div className="row">
                                            <div className="col d-flex w-100 justify-content-between">
                                                <div>
                                                    {transaction.transactionType === "ticket" &&
                                                    <span>Gala & Auction Tickets</span>
                                                    }
                                                    {transaction.transactionType === "donation" &&
                                                    <span>Donation</span>
                                                    }
                                                    {transaction.transactionType === "auction_item" &&
                                                    <span>Auction Item</span>
                                                    }
                                                    <br/>
                                                    <span className="text-muted">{moment(new Date(transaction.createdAt * 1000)).format("MMMM Do YYYY h:mm a")}</span>
                                                </div>
                                                <div className="my-auto">
                                                    <span className="badge badge-success badge-height">${transaction.amount / 100}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    )}
                                    {transactions.length === 0 &&
                                    <li className="list-group-item flex-column mx-auto empty-list">
                                        <p className="text-muted mt-3">No transactions</p>
                                    </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="card mt-4 mt-sm-0">
                            <div className="card-header">
                                Watchlist
                            </div>
                            <div className="card-body m-0 p-0">
                                <ul className="list-group list-group-flush m-0 p-0">
                                    {watchList.map((item) =>
                                    <li key={item.id} className="list-group-item flex-column align-items-start auction-pointer" 
                                            onClick={(e) => history.push("/auction/online/"+item.auction.id+"/item/"+item.id)}>
                                        <div className="row">
                                            <div className="col my-auto">
                                                {item.title}
                                            </div>
                                            <div className="col-4 my-auto">
                                                <label className="badge badge-success">${item.highestBid.bidAmount}.00</label>
                                            </div>
                                        </div>
                                    </li>
                                    )}
                                    {watchList.length === 0 &&
                                    <li className="list-group-item flex-column mx-auto empty-list">
                                        <p className="text-muted mt-3">No items on watchlist</p>
                                    </li>
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="card mt-4">
                            <div className="card-header">
                                Credit Cards
                            </div>
                            <div className="card-body m-0 p-0">
                                <ul className="list-group list-group-flush m-0 p-0">
                                    {paymentMethods.map((paymentMethod) =>
                                    <li key={paymentMethod.id} className="list-group-item flex-column align-items-start">
                                        <div className="row">
                                            <div className="col-1 mr-4 my-auto">
                                                {paymentMethod.cardType === "Visa" && 
                                                <FontAwesomeIcon size="lg" icon={faCcVisa} />
                                                }
                                                {paymentMethod.cardType === "American Express" && 
                                                <FontAwesomeIcon size="lg" icon={faCcAmex} />
                                                }
                                                {paymentMethod.cardType === "MasterCard" &&
                                                <FontAwesomeIcon size="lg" icon={faCcMastercard} />
                                                }
                                                {paymentMethod.cardType === "Discover" &&
                                                <FontAwesomeIcon size="lg" icon={faCcDiscover} />
                                                }
                                                {paymentMethod.cardType === "DinersClub" &&
                                                <FontAwesomeIcon size="lg" icon={faCcDinersClub} />
                                                }
                                            </div>
                                            <div className="col">
                                                xxxx-{paymentMethod.lastFour}<br/>
                                                <span className="text-muted">Expires {paymentMethod.expMonth}/{paymentMethod.expYear}</span>
                                            </div>
                                        </div>
                                    </li>
                                    )}
                                    {paymentMethods.length === 0 &&
                                    <li className="list-group-item flex-column mx-auto empty-list">
                                        <p className="text-muted mt-3">No credit cards saved</p>
                                    </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer history={history} />
        </div>
    );
}

export default withRouter(DashboardPage);
