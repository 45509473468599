import React from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Elements } from "react-stripe-elements";
import DonatePayment from "../../components/DonatePayment";

interface DonatePageProps {
    history: History;
}

const DonatePage: React.FC<DonatePageProps> = ({history}: DonatePageProps) => {
    return (
        <div className="d-flex flex-column full-height">
            <Navbar history={history} />
            <div className="container flex-grow-1 mt-4 mb-4">
                <div className="row mt-4 justify-content-center">
                    <div className="col-sm-6">
                        <h5 className="section-header text-center">Donate</h5>
                        <div className="card">
                            <div className="card-body">
                                <Elements>
                                    <DonatePayment history={history} />
                                </Elements>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer history={history} />
        </div>
    );
}

export default withRouter(DonatePage);
