import TicketPurchase from "./interfaces/TicketPurchase";
import Authenticator from "./Authenticator";
import { Result } from "./interfaces/Result";
import { ServiceError } from "./interfaces/Error";
import Event from "./interfaces/Event";

const axios = require("axios").default;

export default class EventService {
    async purchaseTickets(purchase: TicketPurchase, cardId: string): Promise<boolean> {
        const authenticator = new Authenticator();
        
        try {
            const ticketResponse = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/tickets/purchase`, {
                "cardId": cardId,
                "numGuests": purchase.numGuests,
                "numGuestsDiscounted": purchase.numGuestsDiscounted,
                "numGuestsSponsored": purchase.numGuestsSponsored,
                "name": purchase.guestCardName,
                "seatingNotes": purchase.seatingNotes,
                "childGrade": purchase.childGrade
            }, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (ticketResponse.status === 201) {
                return true;
            }

            return false;
        } catch (error) {
            return false;
        }
    }

    async getCurrentEvent(): Promise<Result<Event>> {
        try {
            const response = await axios.get(`${process.env.REACT_APP_GALA_API_HOST}/events/current`);
            if (response.status === 200) {
                const event: Event = response.data;
                return event;
            }

            return new ServiceError(response.status, response.data.message);
        } catch (error) {
            if (error.response != null) {
                return new ServiceError(error.response.status, error.response.data.message);
            }

            return new ServiceError(-1, "Unable to make the network request.");
        }
    }
}
