import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import EventService from "../../core/EventService";
import { isSuccess } from "../../core/interfaces/Result";
import AuctionService from "../../core/AuctionService";
import { Auction, AuctionItem } from "../../core/interfaces/Auction";
import LiveAuctionItemCollection from "../../components/LiveAuctionItemCollection";

async function loadEvent(setAuctionItems: React.Dispatch<AuctionItem[]>) {
    const service = new EventService();

    const event = await service.getCurrentEvent();
    if (isSuccess(event)) {
        const auctions = await loadAuctionsForEvent(event.id);

        for (let auction of auctions) {
            if (auction.auctionType === "Live Auction") {
                const items = await loadAuctionItems(auction.id);
                setAuctionItems(items);
            }
        }
    }
}

async function loadAuctionsForEvent(eventId: number): Promise<Auction[]> {
    const service = new AuctionService();

    const auctions = await service.getAuctions(eventId);
    if (isSuccess(auctions)) {
        return auctions;
    }

    return [];
}

async function loadAuctionItems(auctionId: number): Promise<AuctionItem[]> {
    const service = new AuctionService();

    const items = await service.getAuctionItems(auctionId);
    if (isSuccess(items)) {
        return items;
    }

    return [];
}

interface LiveAuctionPageProps {
    history: History;
}

const LiveAuctionPage: React.FC<LiveAuctionPageProps> = ({history}: LiveAuctionPageProps) => {
    const [auctionItems, setAuctionItems] = useState<AuctionItem[]>([]);

    useEffect(() => {
        loadEvent(setAuctionItems);
    }, []);

    return (
        <div className="d-flex flex-column full-height">
            <Navbar history={history} />
            <div className="container flex-grow-1 mt-4 mb-4">
                <div className="text-muted mt-3 text-center w-100">Auction items will be posted soon.</div>
            </div>
            <Footer history={history} />
        </div>
    );
}

export default withRouter(LiveAuctionPage);
