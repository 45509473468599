import React from "react";
import {CardElement, injectStripe} from 'react-stripe-elements';
import $ from "jquery";

import PaymentService from "../core/PaymentService";

interface State {
}

class AddPaymentMethodForm extends React.Component<any, State> {
    constructor(props) {
        super(props);
    }

    onFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        $("#payment-alert").hide();
        $("#payment-method-buttons").hide();
        $("#payment-method-progress").show();

        const paymentService = new PaymentService();

        const name = $("#name-on-card").prop("value");
        if (name === undefined || name === null || name === "") {
            $("#payment-method-buttons").show();
            $("#payment-method-progress").hide();

            $("#payment-alert").text("Please provide the name on your credit card.");
            $("#payment-alert").show();

            console.log("Error with the provided name");
            return;
        }

        const { token, error } = await this.props.stripe.createToken({
            "name": name
        });

        if (error != null) {
            $("#payment-method-buttons").show();
            $("#payment-method-progress").hide();

            $("#payment-alert").text(error.message);
            $("#payment-alert").show();

            console.log("Error with creating the stripe token: " + JSON.stringify(error));
            return;
        }

        const paymentMethod = {
            id: "",
            cardId: "",
            tokenId: token.id,
            cardType: token.card.brand,
            expMonth: token.card.exp_month,
            expYear: token.card.exp_year,
            lastFour: token.card.last4
        };

        const cardId = await paymentService.addPaymentMethod(paymentMethod);
        if (cardId == null) {
            $("#payment-method-buttons").show();
            $("#payment-method-progress").hide();

            $("#payment-alert").text("There was an error adding your credit card. Please try again. If the problem persists email help@stmartinpsa.org.");
            $("#payment-alert").show();

            return;
        }

        $("#payment-method-modal").modal("hide");
        this.props.onPaymentMethodAdded();
    }

    render() {
        return (
            <div>
                <div id="payment-method-modal" className="modal" tabIndex={-1} role="dialog">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <form onSubmit={this.onFormSubmit}>
                                <div className="modal-header">Add Credit Card</div>
                                <div className="modal-body">
                                    <div id="payment-alert" className="alert alert-danger collapse mt-2"></div>
                                    <div id="new-credit-card-form">
                                        <div className="form-group mt-3">
                                            <input type="text" className="form-control" id="name-on-card" name="name-on-card"
                                                placeholder="Name on card" inputMode="text" />
                                        </div>
                                        <div className="form-group mt-2 p-2 form-control">
                                            <CardElement />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="d-flex w-100">
                                        <div id="payment-method-buttons" className="ml-auto">
                                            <button type="button" className="btn btn-sm btn-outline-danger" onClick={e => $("#payment-method-modal").modal("hide")}>Cancel</button>
                                            <button className="btn btn-sm btn-outline-primary ml-4" type="submit">Add Credit Card</button>
                                        </div>
                                        <div id="payment-method-progress" className="ml-auto collapse">
                                            <div id="upload-loading" className="spinner-border text-primary" role="status">
                                                <span className="sr-only">Please wait...</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectStripe(AddPaymentMethodForm);