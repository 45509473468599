import React from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

interface DonateConfirmationPageProps {
    history: History;
}

const DonateConfirmationPage: React.FC<DonateConfirmationPageProps> = ({history}: DonateConfirmationPageProps) => {
    const donationAmount = history.location.state.donationAmount;
    const name = history.location.state.name;
    const email = history.location.state.email;
    const paymentMethod = history.location.state.paymentMethod;

    return (
        <div className="d-flex flex-column full-height">
            <Navbar history={history} />
            <div className="container flex-grow-1 mt-4 mb-4">
                <div className="row mt-4 justify-content-center">
                    <div className="col-sm-6">
                        <h5 className="section-header text-center">Thank you for your donation</h5>
                        <p className="mt-4">
                            Thank you for your generous contribution to St. Martin de Porres School. Below is the confirmation of your donation.
                        </p>
                        <ul className="list-group mt-4">
                            <li className="list-group-item list-group-item-secondary">
                                <span className="font-weight-bold">Donation Summary</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                <span>
                                    <span className="font-weight-bold">Name</span><br/>
                                    {name}
                                </span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                <span>
                                    <span className="font-weight-bold">Email</span><br/>
                                    {email}
                                </span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                <span>
                                    <span className="font-weight-bold">Credit Card</span><br/>
                                    {paymentMethod.cardType} xxxx-{paymentMethod.lastFour}
                                </span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                <span>
                                    <span className="font-weight-bold">Donation Amount</span><br/>
                                    $ {donationAmount}.00
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer history={history} />
        </div>
    );
}

export default withRouter(DonateConfirmationPage);
