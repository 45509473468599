import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import Showdown from "showdown";
import $ from "jquery";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { AuctionItem } from "../../core/interfaces/Auction";
import AuctionService from "../../core/AuctionService";
import { isSuccess } from "../../core/interfaces/Result";
import Authenticator from "../../core/Authenticator";
import { Bid } from "../../core/interfaces/Auction";
import moment from "moment";
import PaymentService from "../../core/PaymentService";
import AddPaymentMethod from "../../components/AddPaymentMethod";
import { Elements } from "react-stripe-elements";
import { isError } from "util";

async function loadAuctionItem(auctionId: number, itemId: number, setItem: React.Dispatch<AuctionItem>, setHtml: React.Dispatch<any>, setMinimumPrice: React.Dispatch<number>, bidPrice: number, setBidPrice: React.Dispatch<number>) {
    const service = new AuctionService();

    const item = await service.getAuctionItem(auctionId, itemId);
    if (isSuccess(item)) {
        setItem(item);

        const mdConverter = new Showdown.Converter({tables: true, simplifiedAutoLink: true, tasklists: false, strikethrough: true});
        setHtml(createMarkup(mdConverter.makeHtml(item.pageContent)));

        if (item.highestBid.bidAmount > 0) {
            setMinimumPrice(item.highestBid.bidAmount + item.bidIncrement);

            if (bidPrice == null || bidPrice < item.highestBid.bidAmount + item.bidIncrement) {
                setBidPrice(item.highestBid.bidAmount + item.bidIncrement);
            }
        } else {
            setMinimumPrice(item.startingPrice);
            setBidPrice(item.startingPrice);
        }
    }
}

async function loadBids(auctionItemId: number, setBids: React.Dispatch<Bid[]>) {
    const service = new AuctionService();
    
    const bids = await service.getBids(auctionItemId);
    if (isSuccess(bids)) {
        setBids(bids);
    }
}

async function loadPaymentMethods(setHasPaymentMethods: React.Dispatch<boolean>) {
    const service = new PaymentService();
    
    const methods = await service.getPaymentMethods();
    setHasPaymentMethods(methods.length !== 0);
}

function createMarkup(content: string) {
    return {__html: content}
}

async function placeBid(itemId: number, bidAmount: number) {
    $("#bid-alert").hide();
    $("#bid-button").hide();
    $("#bid-loading").show();
    
    const service = new AuctionService();
    
    const result = await service.placeBid(itemId, bidAmount);
    if (isError(result)) {
        $("#bid-alert").text(result.message);
        $("#bid-alert").show();
    }

    $("#bid-button").show();
    $("#bid-loading").hide();
}

async function addToWatchList(itemId: number) {
    const service = new AuctionService();
    await service.addToWatchList(itemId);
}

async function removeFromWatchList(itemId: number) {
    const service = new AuctionService();
    await service.removeFromWatchList(itemId);
}

interface AuctionItemPageProps {
    history: History;
}

const AuctionItemPage: React.FC<AuctionItemPageProps> = ({history}: AuctionItemPageProps) => {
    const authenticator = new Authenticator();

    const auctionId = Number(history.location.pathname.split("/")[3]);
    const itemId = Number(history.location.pathname.split("/")[5]);

    const [item, setItem] = useState();
    const [html, setHtml] = useState();
    const [minimumPrice, setMinimumPrice] = useState();
    const [bidPrice, setBidPrice] = useState();

    const [bids, setBids] = useState<Bid[]>([]);
    const [hasPaymentMethods, setHasPaymentMethods] = useState();

    useEffect(() => {
        loadAuctionItem(auctionId, itemId, setItem, setHtml, setMinimumPrice, bidPrice, setBidPrice);
        loadBids(itemId, setBids);
        loadPaymentMethods(setHasPaymentMethods);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            loadAuctionItem(auctionId, itemId, setItem, setHtml, setMinimumPrice, bidPrice, setBidPrice);
            loadBids(itemId, setBids);
        }, 5000);
        return () => {
            clearInterval(interval);
        }
    }, [bidPrice]);

    const onPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => setBidPrice(Number(event.target.value));

    const bidFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        await placeBid(itemId, Number(bidPrice));
        await loadAuctionItem(auctionId, itemId, setItem, setHtml, setMinimumPrice, bidPrice, setBidPrice);
        await loadBids(itemId, setBids);
    };

    const onAddToWatchListClicked = async (event: React.MouseEvent) => {
        event.preventDefault();

        await addToWatchList(itemId);
        await loadAuctionItem(auctionId, itemId, setItem, setHtml, setMinimumPrice, bidPrice, setBidPrice);
    };

    const onRemoveFromWatchListClicked = async (event: React.MouseEvent) => {
        event.preventDefault();

        await removeFromWatchList(itemId);
        await loadAuctionItem(auctionId, itemId, setItem, setHtml, setMinimumPrice, bidPrice, setBidPrice);
    }

    const onPaymentMethodAdded = async () => {
        await loadPaymentMethods(setHasPaymentMethods);
    };

    return (
        <div className="d-flex flex-column full-height">
            <Navbar history={history} />
            {item != undefined &&
            <div className="container flex-grow-1 mt-4 mb-4">
                <div className="row">
                    <Elements>
                        <AddPaymentMethod onPaymentMethodAdded={onPaymentMethodAdded} />
                    </Elements>
                    <div className="col-sm-8">
                        {item.images.map((image) =>
                        <img key={image.id} src={image.url} className="w-100 carousel-img img-border" />
                        )}
                        <h2 className="mt-3">{item.title}</h2>
                        <div className="mt-4" dangerouslySetInnerHTML={html}>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        {item.isWinning === true &&
                        <div className="alert alert-success" role="alert">You are currently the highest bidder.</div>
                        }
                        <div id="bid-alert" className="alert alert-danger collapse" role="alert"></div>
                        <div className="card">
                            <div className="card-header">
                                Place a Bid
                            </div>
                            <div className="card-body">
                                <form onSubmit={bidFormSubmit}>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">$</span>
                                        </div>
                                        <input id="item-bid-price" name="item-bid-price" type="text" className="form-control"
                                                value={bidPrice} placeholder={bidPrice?.toString()} onChange={onPriceChange} />
                                        <div className="input-group-append">
                                            <span className="input-group-text">.00</span>
                                        </div>
                                    </div>
                                    <small className="text-muted">Enter a value of <span className="font-weight-bold">${minimumPrice}.00</span> or more.</small>
                                    {!authenticator.isAuthenticated() &&
                                    <button type="button" className="btn btn-success w-100 mt-4" onClick={(e) => history.push("/login?origin="+history.location.pathname)}>Login / Signup to Bid</button>
                                    }
                                    {(authenticator.isAuthenticated() && hasPaymentMethods && item.auction.hasStarted && !item.auction.hasEnded) &&
                                    <button id="bid-button" type="submit" className="btn btn-success w-100 mt-4">Place Bid</button>
                                    }
                                    {(authenticator.isAuthenticated() && !hasPaymentMethods && item.auction.hasStarted && !item.auction.hasEnded) &&
                                    <button type="button" className="btn btn-success w-100 mt-4" onClick={(e) => {$("#payment-method-modal").modal("show")}}>Add Credit Card to Bid</button>
                                    }
                                    {(item.auction.hasEnded) &&
                                    <button type="button" className="btn btn-success w-100 mt-4" disabled>Auction has ended</button>
                                    }
                                    <div className="d-flex justify-content-center">
                                        <div id="bid-loading" className="spinner-border mt-4 mb-4 collapse" role="status">
                                            <span className="sr-only">Placing bid...</span>
                                        </div>
                                    </div>
                                    {(item.auction.hasStarted && !item.auction.hasEnded) &&
                                    <div className="mt-2">
                                        <small className="text-muted">Auction ends {moment(item.auction.endTime * 1000).format("MMMM Do YYYY [at] h:mm a")}</small>
                                    </div>
                                    }
                                    {(!item.auction.hasStarted) &&
                                    <div className="mt-2">
                                        <small className="text-muted">Auction starts {moment(item.auction.startTime * 1000).format("MMMM Do YYYY [at] h:mm a")}</small>
                                    </div>
                                    }
                                    
                                </form>
                            </div>
                        </div>
                        {(authenticator.isAuthenticated() && !item.onWatchList && !item.auction.hasEnded) &&
                        <button type="button" className="btn btn-outline-success w-100 mt-sm-4 mt-4" onClick={onAddToWatchListClicked}>Add to Watchlist</button>
                        }
                        {(authenticator.isAuthenticated() && item.onWatchList && !item.auction.hasEnded) &&
                        <button type="button" className="btn btn-outline-danger w-100 mt-sm-4 mt-4" onClick={onRemoveFromWatchListClicked}>Remove from Watchlist</button>
                        }
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-sm-8">
                        <div className="card">
                            <div className="card-header">
                                Bids
                            </div>
                            <div className="card-body m-0 p-0">
                                <ul className="list-group list-group-flush m-0 p-0">
                                    {bids.length === 0 &&
                                    <li className="list-group-item flex-column mx-auto empty-list">
                                        <p className="text-muted mt-3">No bids</p>
                                    </li>
                                    }
                                    {bids.map((bid) => 
                                    <li key={bid.id} className="list-group-item d-flex w-100 justify-content-between">
                                        <div>
                                            <span className="text-muted">Bid Placed</span>
                                            <br/>
                                            <span className="">{moment(bid.createdAt * 1000).format("MMMM Do, YYYY h:mm a")}</span>
                                        </div>
                                        <div className="ml-auto my-auto">
                                            <label className="badge badge-success">${bid.bidAmount}.00</label>
                                        </div>
                                    </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            <Footer history={history} />
        </div>
    );
}

export default withRouter(AuctionItemPage);
