import React from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import $ from "jquery";

import Authenticator from "../core/Authenticator";
import { PaymentMethod } from "../core/interfaces/PaymentMethod";
import PaymentService from "../core/PaymentService";
import User from "../core/interfaces/User";

interface State {
    user: User | null;
    paymentMethods: PaymentMethod[];
    donationAmount: number;
    email: string;
}

class DonatePayment extends React.Component<any, State> {
    cardId: string | null = null;

    constructor(props) {
        super(props);

        const authenticator = new Authenticator();
        const user = authenticator.getUser();

        this.state = { user: user, paymentMethods: [], donationAmount: 0, email: "" };
    }

    async componentDidMount() {
        const authenticator = new Authenticator();
        const user = authenticator.getUser();

        if (user != null) {
            const paymentService = new PaymentService();
            const paymentMethods = await paymentService.getPaymentMethods();

            this.setState((prevState, props) => ({
                user: user,
                paymentMethods: paymentMethods,
                donationAmount: 0,
                email: user.email
            }));

            if (paymentMethods.length > 0) {
                $("#new-credit-card-form").hide();
                $("#payment-method").val(paymentMethods[0].cardId);
            } else {
                $("#new-credit-card-form").show();
                $("#payment-method").val("new-credit-card");
            }

            $("#email-form-group").hide();
        } else {
            $("#existing-payment-methods").hide();
            $("#new-credit-card-form").show();
            $("#email-form-group").show();
        }
    }

    onPaymentMethodChanged = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === "new-credit-card") {
            $("#new-credit-card-form").show();
        } else {
            $("#new-credit-card-form").hide();
        }
    }

    onDonationAmountChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const donationAmount = Number($("#donation-amount").prop("value"));

        this.setState((prevState, props) => ({
            user: prevState.user,
            paymentMethods: prevState.paymentMethods,
            donationAmount: donationAmount
        }));
    }

    onEmailChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value;
        this.setState((prevState, props) => ({
            user: prevState.user,
            paymentMethods: prevState.paymentMethods,
            donationAmount: prevState.donationAmount,
            email: email
        }));
    }

    onFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        $("#submit-button").hide();
        $("#donation-loading").show();
        $("#payment-alert").hide();

        const paymentService = new PaymentService();

        const authenticator = new Authenticator();
        const user = authenticator.getUser();

        if (user != null) {
            let paymentMethod: PaymentMethod | null = null;

            const paymentMethodType = $("#payment-method").prop("value");
            if (paymentMethodType === "new-credit-card") {
                const name = $("#name-on-card").prop("value");
                if (name === undefined || name === null || name === "") {
                    $("#submit-button").show();
                    $("#donation-loading").hide();

                    $("#payment-alert").text("Please provide the name on your credit card.");
                    $("#payment-alert").show();
                    return;
                }

                const { token, error } = await this.props.stripe.createToken({
                    "name": name
                });

                if (error != null) {
                    $("#submit-button").show();
                    $("#donation-loading").hide();

                    $("#payment-alert").text(error.message);
                    $("#payment-alert").show();
                    return;
                }

                paymentMethod = {
                    id: "",
                    cardId: "",
                    tokenId: token.id,
                    cardType: token.card.brand,
                    expMonth: token.card.exp_month,
                    expYear: token.card.exp_year,
                    lastFour: token.card.last4
                };

                this.cardId = await paymentService.addPaymentMethod(paymentMethod);
                if (this.cardId == null) {
                    $("#submit-button").show();
                    $("#donation-loading").hide();

                    $("#payment-alert").text("There was an error adding your credit card. Please try again. If the problem persists email help@stmartinpsa.org.");
                    $("#payment-alert").show();
                }
            } else {
                this.cardId = $("#payment-method").prop("value");

                this.state.paymentMethods.forEach((pm) => {
                    if (pm.cardId === this.cardId) {
                        paymentMethod = pm;
                    }
                })
            }

            if (this.cardId != null) {
                const donationAmount = Number($("#donation-amount").prop("value"));

                const success = await paymentService.chargeDonationForUser(this.cardId, donationAmount);
                if (success) {
                    this.props.history.replace("/donate/confirmation", {
                        "name": user.firstName + " " + user.lastName,
                        "email": user.email,
                        "donationAmount": donationAmount,
                        "paymentMethod": paymentMethod
                    });
                } else {
                    $("#submit-button").show();
                    $("#donation-loading").hide();
                    
                    $("#payment-alert").text("There was an error processing your payment.");
                    $("#payment-alert").show();
                }
            }
        } else {
            const email = $("#donate-email").prop("value");
            if (email === undefined || email === null || email === "") {
                $("#submit-button").show();
                $("#donation-loading").hide();

                $("#payment-alert").text("Please provide your email address.");
                $("#payment-alert").show();
                return;
            }

            const name = $("#name-on-card").prop("value");
            if (name === undefined || name === null || name === "") {
                $("#submit-button").show();
                $("#donation-loading").hide();

                $("#payment-alert").text("Please provide the name on your credit card.");
                $("#payment-alert").show();
                return;
            }

            const { token, error } = await this.props.stripe.createToken({
                "name": name,
                "email": email
            });

            if (error != null) {
                $("#submit-button").show();
                $("#donation-loading").hide();

                $("#payment-alert").text(error.message);
                $("#payment-alert").show();

                return;
            }

            const donationAmount = Number($("#donation-amount").prop("value"));

            const success = await paymentService.chargeDonationForGuest(token.id, name, email, token.cardType, token.last4, donationAmount);
            if (success) {
                this.props.history.replace("/donate/confirmation", {
                    "donationAmount": donationAmount,
                    "name": name,
                    "email": email,
                    "paymentMethod": {
                        "cardType": token.card.brand,
                        "lastFour": token.card.last4
                    }
                })
            } else {
                $("#submit-button").show();
                $("#donation-loading").hide();

                $("#payment-alert").text("There was an error processing your payment.");
                $("#payment-alert").show();
            }
        }
    }

    render() {
        return(
            <div>
                <form onSubmit={this.onFormSubmit}>
                    <div id="email-form-group" className="form-group">
                        <label htmlFor="payment-method" className="text-muted form-label">Email</label>
                        <input type="text" className="form-control" id="donate-email" name="donate-email" 
                                placeholder="" inputMode="text" onChange={this.onEmailChanged} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="payment-method" className="text-muted form-label">Donation Amount</label>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <input id="donation-amount" type="number" className="form-control" inputMode="numeric" pattern="[0-9]*" placeholder="" onChange={this.onDonationAmountChanged} />
                            <div className="input-group-append">
                                <span className="input-group-text">.00</span>
                            </div>
                        </div>
                    </div>
                    <div id="existing-payment-methods" className="form-group">
                        <label htmlFor="payment-method" className="text-muted form-label">Payment Method</label>
                        <select id="payment-method" className="form-control" onChange={this.onPaymentMethodChanged}>
                            {this.state.paymentMethods.map((paymentMethod: PaymentMethod) =>
                            <option key={paymentMethod.cardId} value={paymentMethod.cardId}>{paymentMethod.cardType} xxxx-{paymentMethod.lastFour} {paymentMethod.expMonth}/{paymentMethod.expYear}</option>
                            )}
                            <option key="new-credit-card" value="new-credit-card">New Credit Card</option>
                        </select>
                    </div>
                    <div id="new-credit-card-form" className="form-group">
                        <div className="form-group mt-3">
                            <label htmlFor="name-on-card" className="text-muted form-label">Name on Card</label>
                            <input type="text" className="form-control" id="name-on-card" name="name-on-card"
                                placeholder="" inputMode="text" />
                        </div>
                        <div id="new-credit-card-form" className="form-group mt-2 p-2 form-control">
                            <CardElement /> 
                        </div>
                    </div>
                    <div>
                        <small>
                            By clicking <span className="font-weight-bold">Submit Donation</span> you authorize St. Martin de Porres PSA to charge your credit card in the amount of <span className="font-weight-bold">${this.state.donationAmount}</span>. A receipt will be emailed to <span className="font-weight-bold">{this.state.email}</span>.
                        </small>
                    </div>
                    <div className="mt-4 text-center">
                        <div className="d-flex justify-content-center">
                            <div id="donation-loading" className="spinner-border collapse" role="status">
                                <span className="sr-only">Completing donation. Please wait...</span>
                            </div>
                        </div>
                        <div id="payment-alert" className="alert alert-danger collapse mt-2"></div>
                        <button id="submit-button" type="submit" className="btn btn-primary col-12">Submit Donation</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default injectStripe(DonatePayment);
