import React from "react";
import {CardElement, injectStripe} from 'react-stripe-elements';
import $ from "jquery";

import Authenticator from "../core/Authenticator";
import { PaymentMethod } from "../core/interfaces/PaymentMethod";
import EventService from "../core/EventService";
import TicketPurchase from "../core/interfaces/TicketPurchase";
import PaymentService from "../core/PaymentService";
import User from "../core/interfaces/User";

interface PaymentFormProps {
    purchase: TicketPurchase;
    paymentTotal: number;
    history: History;
}

interface State {
    user: User | null;
    paymentMethods: PaymentMethod[];
}

class PaymentForm extends React.Component<any, State> {
    cardId: string | null = null;

    constructor(props) {
        super(props);
        
        const authenticator = new Authenticator();
        const user = authenticator.getUser();

        this.state = { user: user, paymentMethods: [] };
    }

    onPaymentMethodChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === "new-credit-card") {
            $("#new-credit-card-form").show();
        } else {
            $("#new-credit-card-form").hide();
        }
    }

    onFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        $("#submit-button").hide();
        $("#tickets-loading").show();
        $("#payment-alert").hide();

        const paymentService = new PaymentService();

        let paymentMethod: PaymentMethod | null = null;

        const paymentMethodType = $("#formPaymentMethod").prop("value");
        if (paymentMethodType === "new-credit-card") {
            const name = $("#formNameOnCard").prop("value");
            if (name === undefined || name === null || name === "") {
                $("#submit-button").show();
                $("#tickets-loading").hide();

                $("#payment-alert").text("Please provide the name on your credit card.");
                $("#payment-alert").show();

                console.log("Error with the provided name");
                return;
            }

            const { token, error } = await this.props.stripe.createToken({
                "name": name
            });

            if (error != null) {
                $("#submit-button").show();
                $("#tickets-loading").hide();

                $("#payment-alert").text(error.message);
                $("#payment-alert").show();

                console.log("Error with creating the stripe token: " + JSON.stringify(error));
                return;
            }

            paymentMethod = {
                id: "",
                cardId: "",
                tokenId: token.id,
                cardType: token.card.brand,
                expMonth: token.card.exp_month,
                expYear: token.card.exp_year,
                lastFour: token.card.last4
            };

            this.cardId = await paymentService.addPaymentMethod(paymentMethod);
            if (this.cardId == null) {
                $("#submit-button").show();
                $("#tickets-loading").hide();

                $("#payment-alert").text("There was an error adding your credit card. Please try again. If the problem persists email help@stmartinpsa.org.");
                $("#payment-alert").show();
            }
        } else {
            this.cardId = $("#formPaymentMethod").prop("value");

            this.state.paymentMethods.forEach((pm) => {
                if (pm.cardId === this.cardId) {
                    paymentMethod = pm;
                }
            });
        }
        
        if (this.cardId != null) {
            const eventService = new EventService();
            const success = await eventService.purchaseTickets(this.props.purchase, this.cardId);

            if (success) {
                this.props.history.replace("/tickets/confirmation", {
                    "purchase": this.props.purchase,
                    "paymentMethod": paymentMethod
                });
            } else {
                $("#submit-button").show();
                $("#tickets-loading").hide();
    
                $("#payment-alert").text("There was an error processing your payment. Please try again. If the problem persists email help@stmartinpsa.org.");
                $("#payment-alert").show();
            }
        }
    }

    async componentDidMount() {
        const authenticator = new Authenticator();
        const user = authenticator.getUser();

        if (user == null) {
            this.props.history.replace("/login");
        }

        const paymentService = new PaymentService();

        const paymentMethods = await paymentService.getPaymentMethods();
        this.setState((prevState, props) => ({
            user: user,
            paymentMethods: paymentMethods
        }));

        if (paymentMethods.length == 0) {
            $("#formPaymentMethod").val("new-credit-card");
            $("#new-credit-card-form").show();
        } else {
            $("#formPaymentMethod").val(paymentMethods[0].cardId);
            $("#new-credit-card-form").hide();
        }
    }

    render() {
        return (
            <form onSubmit={this.onFormSubmit}>
                <h5 className="mt-4">Payment Information</h5>
                <div className="form-group mt-3">
                    <label htmlFor="formPaymentMethod" className="col-form-label">Payment Method</label>
                    <select id="formPaymentMethod" className="form-control mt-2" onChange={this.onPaymentMethodChange}>
                        {this.state.paymentMethods.map((paymentMethod: PaymentMethod) =>
                        <option key={paymentMethod.cardId} value={paymentMethod.cardId}>{paymentMethod.cardType} xxxx-{paymentMethod.lastFour} {paymentMethod.expMonth}/{paymentMethod.expYear}</option>
                        )}
                        <option key="new-credit-card" value="new-credit-card">New Credit Card</option>
                    </select>
                </div>
                <div id="new-credit-card-form">
                    <div className="form-group mt-3">
                        <input type="text" className="form-control" id="formNameOnCard" name="formNameOnCard"
                            placeholder="Name on card" inputMode="text" />
                    </div>
                    <div className="form-group mt-2 p-2 form-control">
                        <CardElement />
                    </div>
                </div>
                <div>
                    <small>
                        By clicking <span className="font-weight-bold">Complete Purchase</span> you authorize St. Martin de Porres PSA to charge your credit card in the amount of <span className="font-weight-bold">${this.props.paymentTotal}</span>. A receipt will be emailed to <span className="font-weight-bold">{this.state.user?.email}</span>.
                    </small>
                </div>
                <div className="mt-4 text-center">
                    <div className="d-flex justify-content-center">
                        <div id="tickets-loading" className="spinner-border collapse" role="status">
                            <span className="sr-only">Completing purchase. Please wait...</span>
                        </div>
                    </div>
                    <div id="payment-alert" className="alert alert-danger collapse mt-2"></div>
                    <button id="submit-button" type="submit" className="btn btn-primary col-12">Complete Purchase</button>
                </div>
            </form>
        );
    }
}

export default injectStripe(PaymentForm);
