import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faMapMarkerAlt, faDollarSign, faTshirt } from "@fortawesome/free-solid-svg-icons";

import "./landing.scss";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { AuctionItem } from "../../core/interfaces/Auction";

import sponsor from "../../images/sponsor_001.jpg";
import sponsor2 from "../../images/sponsor_002.png";
import sponsor7 from "../../images/sponsor_007.png";
import sponsor8 from "../../images/sponsor_008.png";
import sponsor21 from "../../images/sponsor_021.png";
import kennedy from "../../images/sponsor_012.jpg";
import lourdes from "../../images/platinum_sponsor.png";
import blondin from "../../images/sponsor_018.png";
import dlFlowTech from "../../images/sponsor_005.jpg";
import effman from "../../images/effman.png";
import hillcroft from "../../images/sponsor_hillcroft.png";
import statefarm from "../../images/sponsor_statefarm.png";
import veith from "../../images/sponsor_veith.png";
import z3 from "../../images/sponsor_017.png";
import hudsonValley from "../../images/hudson_valley_team_sponsor.png";
import homesteadFunding from "../../images/homestead_funding.png";
import sponsor20 from "../../images/sponsor_020.png";
import millbrook from "../../images/millbrook_sponsor.png";
import adams from "../../images/adams_sponsor.png";
import pbaSponsor from "../../images/pba_sponsor.png";
import russoSponsor from "../../images/russo_sponsors.png";
import mjn from "../../images/mjn_sponsor.png";
import lola from "../../images/sponsor_lola.png";

import galaImage from "../../images/gala_2024_01.jpg";

import schoolLogo from "../../images/school-logo.svg";
import Authenticator from "../../core/Authenticator";
import AuctionService from "../../core/AuctionService";
import AuctionItemCollection from "../../components/AuctionItemCollection";
import { isSuccess } from "../../core/interfaces/Result";

interface LandingProps {
    history: History;
}

async function getFeaturedAuctionItems(setAuctionItems: React.Dispatch<AuctionItem[]>) {
    const service = new AuctionService();

    const items = await service.getFeaturedAuctionItems();
    if (isSuccess(items)) {
        setAuctionItems(items);
    }
}

const Landing: React.FC<LandingProps> = ({history}: LandingProps) => {
    const authenticator = new Authenticator();

    const handleButtonClick = async (event: React.MouseEvent) => {
        event.preventDefault();
        history.push("/tickets");
    }

    const [auctionItems, setAuctionItems] = useState<AuctionItem[]>([]);

    useEffect(() => {
        getFeaturedAuctionItems(setAuctionItems);
    }, []);

    return (
        <div>
            <Navbar history={history} />
            <div className="container">
                <div className="row mt-4">
                    <div className="col-sm-12">
                        <div className="text-center">
                            <img src={schoolLogo} className="about-logo" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex masthead pt-3 pb-2">
                <div className="d-flex container align-items-center justify-content-center">
                    <div className="row w-100 justify-content-center">
                        <div className="col-sm-6">
                            <div className="text-center">
                                <span className="sponsor-label">Our Platinum Sponsors</span>
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <div id="platinum-carousel" className="carousel slide carousel-fade" data-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active text-center">
                                            <img src={dlFlowTech} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={sponsor20} className="sponsor" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-4 mt-sm-0">
                            <div className="text-center">
                                <span className="sponsor-label">Thank you to our sponsors</span>
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <div id="sponsor-carousel" className="carousel slide carousel-fade" data-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active text-center">
                                            <img src={sponsor} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={sponsor2} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={sponsor7} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={sponsor8} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={kennedy} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={lourdes} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={blondin} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={sponsor21} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={effman} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={hillcroft} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={veith} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={z3} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={millbrook} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={hudsonValley} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={adams} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={homesteadFunding} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={statefarm} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={russoSponsor} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={pbaSponsor} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={mjn} className="sponsor" />
                                        </div>
                                        <div className="carousel-item text-center">
                                            <img src={lola} className="sponsor" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="divider-small mt-2 mb-3"></div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-7">
                        <div id="photo-carousel" className="slide carousel-fade" data-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item text-center active">
                                    <img src={galaImage} className="gallery" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-5">
                        <header className="plain">Get ready to dine, dance & donate!</header>
                        <p className="body mt-3">
                            Silent auction items are now available for viewing and bidding. The silent auction will continue through 7pm the evening of the Gala, Saturday, March 9th. You must register online in order to bid. More auction items coming soon.
                        </p>
                    </div>
                </div>
                <div className="divider-small mt-4 mb-3"></div>
                <div className="row mt-4">
                    <div className="col">
                        <header className="sponsor-label text-center">Featured Auctions</header>
                        <p className="text-center text-muted">More auction items coming soon.</p>
                        <div className="mt-4 mb-4">
                        </div>
                        <div className="text-center">
                            {!authenticator.isAuthenticated() &&
                            <button type="button" className="btn btn-success" onClick={(e) => history.push("/login?origin=/auction/online")}>Login / Signup to Bid</button>
                            }
                            {authenticator.isAuthenticated() &&
                            <button type="button" className="btn btn-outline-primary" onClick={(e) => history.push("/auction/online")}>See all auction items</button>
                            }
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-sm-6 mt-2 mt-sm-0">
                        <header className="section-title">
                            <span>Event Information</span>
                        </header>
                        <div className="section">
                            <div className="row">
                                <div className="col-1">
                                    <FontAwesomeIcon icon={faClock} />
                                </div>
                                <div className="col-10">
                                    Saturday March 9, 2024<br/>6 PM - 10 PM
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-1">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                </div>
                                <div className="col-10">
                                    The Academy<br />33 Academy Street<br/>Poughkeepsie, NY 12601
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-1">
                                    <FontAwesomeIcon icon={faTshirt} />
                                </div>
                                <div className="col-10">
                                    Formal / Cocktail Attire
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-4 mt-sm-0">
                        <header className="section-title">
                            <span>Tickets</span>
                        </header>
                        <div className="section">
                            <div className="row">
                                <div className="col-1">
                                    <FontAwesomeIcon icon={faDollarSign} />
                                </div>
                                <div className="col-10">
                                    <span className="numeric">110</span> / person<br />
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="d-flex col-12 justify-content-center">
                                    <button type="button" className="btn btn-outline-primary px-4" onClick={handleButtonClick}>Buy Tickets</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer history={history} />
        </div>
    );
}

export default withRouter(Landing);
