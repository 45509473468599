import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import queryString from "query-string";
import $ from "jquery";

import Authenticator from "../../core/Authenticator";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

interface PasswordResetPageProps {
    history: History;
}

async function executePasswordUpate(password: string, confirmPassword: string, token: string, history: History) {
    if (password !== confirmPassword) {
        $("#password-error-alert").text("Passwords entered do not match.");
        $("#password-error-alert").show();
        return;
    }

    if (password.length < 8) {
        $("#password-error-alert").text("Password is too short.");
        $("#password-error-alert").show();
        return;
    }

    const authenticator = new Authenticator();
    const success = await authenticator.updatePassword(password, token);

    if (success) {
        history.replace("/login");
    } else {
        $("#password-error-alert").text("Password reset expired. Please request a new one.");
        $("#password-error-alert").show();
        return;
    }
}

const PasswordResetPage: React.FC<PasswordResetPageProps> = ({history}: PasswordResetPageProps) => {
    const params = queryString.parse(history.location.search);

    const [password, setPassword] = useState<string>();
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

    const [confirmPassword, setConfirmPassword] = useState<string>();
    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value);

    const formSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        const token = params["token"] as string;
        executePasswordUpate(password, confirmPassword, token, history);
    };

    return (
        <div className="d-flex flex-column full-height">
            <Navbar history={history} />
            <div className="container flex-grow-1 mt-4 mb-4">
                <div className="row justify-content-center">
                    <div className="col-lg-5 order-lg-2">
                        <div className="card">
                            <div className="card-header">
                                Set a new password
                            </div>
                            <div className="card-body">
                                <div id="password-error-alert" className="alert alert-danger collapse"></div>
                                <form onSubmit={formSubmit}>
                                    <input type="password" className="form-control mt-2" placeholder="Password" onChange={handlePasswordChange} />
                                    <input type="password" className="form-control mt-2" placeholder="Confirm Password" onChange={handleConfirmPasswordChange} />
                                    <div className="mt-4 text-center">
                                        <button type="submit" className="btn btn-primary col-12 psa-button">Update Password</button>
                                        <p className="mt-3">
                                            <a className="psa-inline-button" href="mailto:help@stmartinpsa.org">
                                                Need help? Email us.
                                            </a>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="mt-4 text-center">
                            <button type="button" className="btn btn-success w-100" onClick={(e) => history.push("/signup?origin="+origin)}>Don't have an account? Sign up here.</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer history={history} />
        </div>
    );
};

export default withRouter(PasswordResetPage);
