import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import queryString from "query-string";
import $ from "jquery";

import Authenticator from "../../core/Authenticator";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

interface SignupPageProps {
    history: History;
}

async function executeSignup(firstName: string, lastName: string, email: string, password: string, confirmPassword: string, history: History, origin: string) {
    $("#signup-alert").hide();

    if (firstName === undefined || firstName === "") {
        $("#signup-alert").text("First name is required.");
        $("#signup-alert").show();
        
        $("#signup-loading").hide();
        $("#signup-button").show();

        return;
    }

    if (lastName === undefined || lastName === "") {
        $("#signup-alert").text("Last name is required.");
        $("#signup-alert").show();

        $("#signup-loading").hide();
        $("#signup-button").show();

        return;
    }

    if (email === undefined || email === "") {
        $("#signup-alert").text("Email address is required.");
        $("#signup-alert").show();

        $("#signup-loading").hide();
        $("#signup-button").show();

        return;
    }

    if (password === undefined || password === "") {
        $("#signup-alert").text("Password is required.");
        $("#signup-alert").show();

        $("#signup-loading").hide();
        $("#signup-button").show();

        return;
    }

    if (confirmPassword === undefined || confirmPassword === "") {
        $("#signup-alert").text("Please confirm your password.");
        $("#signup-alert").show();

        $("#signup-loading").hide();
        $("#signup-button").show();

        return;
    }

    if (password !== confirmPassword) {
        $("#signup-alert").text("Passwords entered do not match");
        $("#signup-alert").show();

        $("#signup-loading").hide();
        $("#signup-button").show();

        return;
    }

    const authenticator = new Authenticator();
    const user = await authenticator.signUp(firstName, lastName, email, password);

    if (user != null) {
        if (origin != null && origin !== "") {
            history.push(origin);
        } else {
            history.push("/dashboard");
        }
    } else {
        $("#signup-loading").hide();
        $("#signup-button").show();

        $("#signup-alert").text("Error signing up. Please try again.");
        $("#signup-alert").show();
    }
}

const SignupPage: React.FC<SignupPageProps> = ({history}: SignupPageProps) => {
    const authenticator = new Authenticator();
    if (authenticator.isAuthenticated()) {
        history.replace("/dashboard");
    }
    
    const params = queryString.parse(history.location.search);
    const origin = params["origin"] as string;

    const [signupFirstName, setSignupFirstName] = useState();
    const handleSignupFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setSignupFirstName(event.target.value);

    const [signupLastName, setSignupLastName] = useState();
    const handleSignupLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setSignupLastName(event.target.value);

    const [signupEmail, setSignupEmail] = useState();
    const handleSignupEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => setSignupEmail(event.target.value);

    const [signupPassword, setSignupPassword] = useState();
    const handleSignupPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setSignupPassword(event.target.value);

    const [signupConfirmPassword, setSignupConfirmPassword] = useState();
    const handleSignupConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setSignupConfirmPassword(event.target.value);

    const signupFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        executeSignup(signupFirstName, signupLastName, signupEmail, signupPassword, signupConfirmPassword, history, origin);
    };
    
    return (
        <div className="d-flex flex-column full-height">
            <Navbar history={history} />
            <div className="container flex-grow-1 mt-4 mb-4">
                <div className="row justify-content-center">
                    <div className="col-lg-5 order-lg-1">
                        <div className="card">
                            <div className="card-header">
                                Sign Up
                            </div>
                            <div className="card-body">
                                <div id="signup-alert" className="alert alert-danger collapse"></div>
                                <form onSubmit={signupFormSubmit}>
                                    <input type="text" className="form-control mt-2" placeholder="First Name" onChange={handleSignupFirstNameChange} />
                                    <input type="text" className="form-control mt-2" placeholder="Last Name" onChange={handleSignupLastNameChange} />
                                    <input type="email" className="form-control mt-2" placeholder="Email" onChange={handleSignupEmailChange} />
                                    <input type="password" className="form-control mt-2" placeholder="Password" onChange={handleSignupPasswordChange} />
                                    <input type="password" className="form-control mt-2" placeholder="Confirm Password" onChange={handleSignupConfirmPasswordChange} />
                                    <div className="mt-4 text-center">
                                        <div className="d-flex justify-content-center">
                                            <div id="signup-loading" className="spinner-border collapse" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary col-12">Sign Up</button>
                                        <p className="mt-3">
                                            Need help? Contact us at <a className="psa-inline-button" href="mailto:help@stmartinpsa.org">
                                                help@stmartinpsa.org
                                            </a>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer history={history} />
        </div>
    );
}

export default withRouter(SignupPage);
