import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import $ from "jquery";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

interface TicketPurchasePageProps {
    history: History;
}

const TicketPurchasePage: React.FC<TicketPurchasePageProps> = ({history}: TicketPurchasePageProps) => {
    const [numGuests, setNumGuests] = useState();
    const onGuestsChanged = (event: React.ChangeEvent<HTMLInputElement>) => setNumGuests(event.target.value);

    const [numGuestsDiscounted, setNumGuestsDiscounted] = useState();
    const onGuestsDiscountedChanged = (event: React.ChangeEvent<HTMLInputElement>) => setNumGuestsDiscounted(event.target.value);

    const [numGuestsSponsored, setNumGuestsSponsored] = useState();
    const onGuestsSponsoredChanged = (event: React.ChangeEvent<HTMLInputElement>) => setNumGuestsSponsored(event.target.value);

    const [nameCard, setNameCard] = useState();
    const onNameCardChanged = (event: React.ChangeEvent<HTMLInputElement>) => setNameCard(event.target.value);

    const [childGrade, setChildGrade] = useState();
    const onChildGradeChanged = (event: React.ChangeEvent<HTMLInputElement>) => setChildGrade(event.target.value);

    const [seatingNotes, setSeatingNotes] = useState();
    const onSeatingNotesChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => setSeatingNotes(event.target.value);

    const formSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        $("#error-alert").hide();

        let guests = 0;
        if (numGuests !== undefined) {
            guests = Number(numGuests);
        }

        let guestsDiscounted = 0;
        if (numGuestsDiscounted !== undefined) {
            guestsDiscounted = Number(numGuestsDiscounted);
        }

        let guestsSponsored = 0;
        if (numGuestsSponsored !== undefined) {
            guestsSponsored = Number(numGuestsSponsored);
        }

        if (guests === 0 && guestsDiscounted === 0 && guestsSponsored === 0) {
            $("#error-alert").text("Please enter the number of tickets to purchase.");
            $("#error-alert").show();
            return
        }

        history.push("/tickets/checkout", {
            "numGuests": guests,
            "numGuestsDiscounted": guestsDiscounted,
            "numGuestsSponsored": guestsSponsored,
            "guestCardName": nameCard,
            "seatingNotes": seatingNotes,
            "childGrade": childGrade
        });
    };

    return (
        <div>
            <Navbar history={history} />
            <div className="container">
                <div className="row mt-4 justify-content-center">
                    <div className="col-lg-6">
                        <h5 className="section-header text-center">Purchase Tickets</h5>
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={formSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="numGuests" className="col-form-label-sm">Number of Guests @ $110</label>
                                        <input type="number" className="form-control" id="numGuests" name="numGuests" 
                                                placeholder="0" inputMode="numeric" pattern="[0-9]*" value={numGuests} onChange={onGuestsChanged} />
                                    </div>
                                    <div className="form-group collapse">
                                        <label htmlFor="numGuestsDiscounted" className="col-form-label-sm">
                                            Number of Guests @ $65<br />
                                            <small className="text-muted font-weight-bold">Age 70+; School Staff</small>
                                        </label>
                                        <input type="number" className="form-control" id="numGuestsDiscounted" name="numGuestsDiscounted" 
                                                placeholder="0" inputMode="numeric" pattern="[0-9]*" value={numGuestsDiscounted} onChange={onGuestsDiscountedChanged} />
                                    </div>
                                    <div className="form-group collapse">
                                        <label htmlFor="numGuestsSponsored" className="col-form-label-sm">
                                            Sponsored Guests @ $65<br />
                                            <small className="text-muted font-weight-bold">Clergy member / teacher</small>
                                        </label>
                                        <input type="number" className="form-control" id="numGuestsSponsored" name="numGuestsSponsored" placeholder="0" 
                                                inputMode="numeric" pattern="[0-9]*" value={numGuestsSponsored} onChange={onGuestsSponsoredChanged} />
                                    </div>
                                    <div className="divider-small mt-4 mb-3"></div>
                                    <div className="form-group">
                                        <label htmlFor="guestCardName" className="col-form-label-sm">Name for table card</label>
                                        <input type="text" className="form-control" id="guestCardName" name="guestCardName" 
                                                placeholder="Mr. & Mrs. Smith" inputMode="text" value={nameCard} onChange={onNameCardChanged} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="childGrade" className="col-form-label-sm">Grade(s) of children</label>
                                        <input type="text" className="form-control" id="childGrade" name="childGrade" 
                                                placeholder="3,6" inputMode="text" value={childGrade} onChange={onChildGradeChanged} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="guestSeatingNotes" className="col-form-label-sm">People I would like to be seated with</label>
                                        <textarea className="form-control" id="guestSeatingNotes" rows={3} value={seatingNotes} onChange={onSeatingNotesChanged}></textarea>
                                    </div>
                                    <div className="mt-4 text-center">
                                        <div id="error-alert" className="alert alert-danger collapse col-10 mt-4 mb-4 mx-auto"></div>
                                        <button id="submit-button" type="submit" className="btn btn-primary col-8">Proceed to Checkout</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer history={history} />
        </div>
    );
}

export default withRouter(TicketPurchasePage);
