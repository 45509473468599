import React from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import schoolImage from "../../images/school.jpg";
import schoolLogo from "../../images/school-logo.svg";

interface AboutPageProps {
    history: History;
}

const AboutPage: React.FC<AboutPageProps> = ({history}: AboutPageProps) => {
    return (
        <div>
            <Navbar history={history} />
            <div className="container mt-4">
                <div className="row justify-content-center">
                    <div className="col-sm-12 text-center">
                        <img src={schoolLogo} className="about-logo" />
                        <br/>
                        <img src={schoolImage} className="gallery mt-4" />
                    </div>
                </div>
                <div className="row mt-4 justify-content-center">
                    <div className="col-sm-12 text-center">
                        <h5 className="section-header">About Our School</h5>
                    </div>
                </div>
                <div className="row mt-4 justify-content-center">
                    <div className="col-sm-12 text-left">
                        <p>
                            St. Martin de Porres School is a co-educational, Catholic elementary school of Grades Pre-K through 8. Recently designated a National 2012 Blue Ribbon School, St. Martin’s is located in New York’s historic Hudson River Valley on the grounds of St. Martin de Porres Parish, its sponsor. The school’s primary purpose as a Christ-centered community is to teach and practice the message of Jesus Christ. Like its patron, St. Martin, the school makes daily prayer, communal worship, and service to others an essential part of its mission.
                        </p>
                        <p>
                            St. Martin’s follows the curriculum approved by the Archdiocese of New York and offers students the opportunity to develop their God-given gifts to the best of their ability. The school strives for academic excellence, while preparing its students to meet the obligations and challenges of Christian living in a responsible and self-disciplined manner. It fosters close cooperation between teachers and parents, the first and primary educators, in guiding each child toward clarifying values, experiencing a sense of accomplishment, and developing an appreciation of self-worth.
                        </p>
                    </div>
                </div>
            </div>
            <Footer history={history} />
        </div>
    );
}

export default withRouter(AboutPage);
