import React from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

interface TicketConfirmationPageProps {
    history: History;
}

const TicketConfirmationPage: React.FC<TicketConfirmationPageProps> = ({history}: TicketConfirmationPageProps) => {
    const purchase = history.location.state.purchase;
    const paymentMethod = history.location.state.paymentMethod;

    return (
        <div>
            <Navbar history={history} />
            <div className="container mt-4">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <h4 className="text-center">Ticket Purchase Complete</h4>
                        <p className="mt-4">
                            Thank you for your ticket purchase. You will receive an email confirmation momentarily with the details of your purchase.<br/><br/>
                        </p>
                        <ul className="list-group mt-4">
                            <li className="list-group-item list-group-item-secondary">
                                <span className="font-weight-bold">Your Details</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                <span><span className="font-weight-bold">Name for table card</span><br/>{purchase.guestCardName}</span>
                            </li>
                            <li className="list-group-item d-flex">
                                <span><span className="font-weight-bold">Seating Notes</span><br/>{purchase.seatingNotes}</span>
                            </li>
                        </ul>
                        <ul className="list-group mt-4">
                            <li className="list-group-item list-group-item-secondary">
                                <span className="font-weight-bold">Order Summary</span>
                            </li>
                            { purchase.numGuests > 0 &&
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <span>Guests<br/><span className="font-weight-bold">{purchase.numGuests} x $110</span></span> ${purchase.numGuests * 110}
                                </li>
                            }
                            { purchase.numGuestsDiscounted > 0 && 
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <span>Discounted Guests<br/><span className="font-weight-bold">{purchase.numGuestsDiscounted} x $65</span></span> ${purchase.numGuestsDiscounted * 65}
                                </li>
                            }
                            { purchase.numGuestsSponsored > 0 &&
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <span>Sponsored Clergy / Teachers<br/><span className="font-weight-bold">{purchase.numGuestsSponsored} x $65</span></span> ${purchase.numGuestsSponsored * 65}
                                </li>
                            }
                            <li className="list-group-item list-group-item-info d-flex justify-content-between align-items-center">
                                <span className="font-weight-bold">Total:</span> <span className="font-weight-bold">${(purchase.numGuests * 110) + (purchase.numGuestsDiscounted * 65) + (purchase.numGuestsSponsored * 65)}</span>
                            </li>
                        </ul>
                        <ul className="list-group mt-4">
                            <li className="list-group-item list-group-item-secondary">
                                <span className="font-weight-bold">Payment Method</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                <span><span className="font-weight-bold">Credit Card</span><br/>{paymentMethod.cardType} ending in {paymentMethod.lastFour}<br/>{paymentMethod.expMonth}/{paymentMethod.expYear}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer history={history} />
        </div>
    );
}

export default withRouter(TicketConfirmationPage);
