import User from "./interfaces/User";
const axios = require("axios").default;

export default class Authenticator {
    isAuthenticated(): boolean {
        const token = localStorage.getItem(`${process.env.REACT_APP_API_TOKEN_KEY}`);
        return (token != null && token !== undefined && token.length > 0) && this.getUser() != null;
    }

    getToken(): string | null {
        return localStorage.getItem(`${process.env.REACT_APP_API_TOKEN_KEY}`);
    }

    getUser(): User | null {
        const json = localStorage.getItem(`${process.env.REACT_APP_USER_KEY}`);
        if (json == null || json === undefined || json.length <= 0) {
            return null;
        }

        const user: User = JSON.parse(json);
        return user;
    }

    logout() {
        localStorage.removeItem(`${process.env.REACT_APP_API_TOKEN_KEY}`);
        localStorage.removeItem(`${process.env.REACT_APP_USER_KEY}`);
    }

    async signUp(firstName: string, lastName: string, email: string, password: string): Promise<User | null> {
        try {
            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/users/signup`, {
                "firstName": firstName,
                "lastName": lastName,
                "email": email,
                "password": password
            });

            if (response.status === 200) {
                const user: User = response.data;
                localStorage.setItem(`${process.env.REACT_APP_API_TOKEN_KEY}`, response.headers.authorization);
                localStorage.setItem(`${process.env.REACT_APP_USER_KEY}`, JSON.stringify(user));

                return user;
            }

            return null;
        } catch (error) {
            return null;
        }
    }

    async login(email: string, password: string): Promise<User | null> {
        try {
            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/users/authenticate`, {
                "email": email,
                "password": password
            });

            if (response.status === 200) {
                const user: User = response.data;
                localStorage.setItem(`${process.env.REACT_APP_API_TOKEN_KEY}`, response.headers.authorization);
                localStorage.setItem(`${process.env.REACT_APP_USER_KEY}`, JSON.stringify(user));

                return user;
            }

            return null;
        } catch (error) {
            return null;
        }
    }

    async initiatePasswordReset(email: string): Promise<boolean> {
        try {
            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/password/reset`, {
                "email": email,
                "domain": "https://stmartingala.org"
            });

            if (response.status === 202) {
                return true;
            }

            return false;
        } catch (error) {
            return false;
        }
    }

    async updatePassword(password: string, token: string): Promise<boolean> {
        try {
            const response = await axios.post(`${process.env.REACT_APP_GALA_API_HOST}/users/password`, {
                "password": password,
                "tokenDigest": token
            });

            if (response.status !== 200) {
                return false;
            }

            return true;
        } catch (error) {
            return false;
        }
    }
}
