import React from "react";
import { History } from "history";

import Authenticator from "../core/Authenticator";

interface FooterProps {
    history: History;
}

const Footer: React.FC<FooterProps> = ({history}: FooterProps) => {
    const authenticator = new Authenticator();

    const onLogoutClicked = async (event: React.MouseEvent) => {
        authenticator.logout();

        history.replace("/");
    };

    return (
        <footer className="mt-4 flex-shrink-0 mb-auto d-flex w-100 justify-content-between">
            <small className="my-auto">&copy; 2023 St. Martin de Porres School Gala & Auction</small>
            <div>
                {authenticator.isAuthenticated() &&
                <button id="btn-account" className="btn btn-sm btn-outline-primary mx-3 my-sm-0 ml-auto mt-3" onClick={onLogoutClicked}>Logout</button>
                }
            </div>
        </footer>
    );
}

export default Footer;
